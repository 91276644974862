<script>
export default {
  name: 'CustomJunctions',
  components: {},
  props: ['customJunctions'],
  data: () => ({
    model: {}
  }),
  mounted() {
    this.setModel()
  },
  methods: {
    setCustomJunctionValue() {
      this.$emit('setCustomJunctionValues', this.model)
    },
    setModel() {
      this.customJunctions.forEach((variable) => {
        this.$set(this.model, `${variable.letter}`, `${variable.value}`)
      })
    }
  }
}
</script>

<template>
  <ul class="custom-junctions__list">
    <li class="custom-junctions__item" v-for="junction of customJunctions" :key="junction.letter">
      <label class="custom-junctions__label" :for="`custom-junctions-${junction.letter}`">
        <span class="custom-junctions__variable">{{ junction.letter }} = </span>
        <span class="custom-junctions__input">
          <input
            v-model="model[`${junction.letter}`]"
            type="number"
            :id="`custom-junctions-${junction.letter}`"
            @input="setCustomJunctionValue"
          />
          <span class="custom-junctions__unit">{{ $t('message.units.mm') }}</span>
        </span>
      </label>
    </li>
  </ul>
</template>

<style lang="sass">
.custom-junctions
  &__list
    padding: 0
    list-style: none
  &__item:not(:last-child)
    border-bottom: rem(1) solid #D6D6D6
  &__label
    display: flex
    align-items: center
    gap: rem(8)
    margin: rem(10) 0
    font-family: Montserrat,sans-serif
    font-size: rem(12)
  &__input
    height: rem(40)
    padding: 0 rem(10)
    border: rem(1) solid #D6D6D6
    border-radius: rem(4)
    input
      max-width: rem(60)
      height: 100%
      padding: 0
      border: none
      font-family: inherit
      font-size: inherit
      font-weight: 700
      color: $red
      &:focus
        outline: none
  &__unit
    padding: 0 0 0 rem(10)
</style>
