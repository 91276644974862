<template>
  <section class="junctions-nav" v-if="junctions.length > 0">
    <div class="container">
      <div
        class="junctions-nav__toggler"
        :class="{ active: isExpanded }"
        @click="isExpanded = !isExpanded"
      >
        <div class="junctions-nav__toggler-text">
          <i18n path="message.junctions.togglerText">
            <template #word>
              {{ currentWord }}
            </template>
          </i18n>
        </div>
        <div class="junctions-nav__toggler-count">
          <img src="@/assets/img/nav/slide-junctions.svg" alt="" />
          <div class="count">
            {{ this.junctions.length }}
          </div>
        </div>
        <div class="junctions-nav__toggler-chevron">
          <img src="@/assets/img/icons/filter--select.svg" alt="arrow" />
        </div>
      </div>
      <Junctions-nav-list
        :junctions="junctions"
        :isShowDelete="isShowDelete"
        placement="system"
        v-if="isExpanded"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import JunctionsNavList from '@/components/elements/Junction/JunctionsNavList'
export default {
  props: {
    junctions: Array,
    isShowDelete: {
      type: Boolean
    },
    showList: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    isExpanded: false
  }),
  components: {
    JunctionsNavList
  },
  created() {
    if (this.showList) this.isExpanded = true
  },
  computed: {
    ...mapState({
      allJunctions: state => state.junctions
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    currentWord() {
      return this.isExpanded
        ? this.$i18n.t('message.computed.hide')
        : this.$i18n.t('message.computed.show')
    }
  }
}
</script>

<style scoped lang="sass">
.junctions-nav
  margin-top: rem(-8)
  &__toggler
    background: rgba(255, 255, 255, 0.5)
    border-radius: rem(4)
    display: grid
    +media((grid-template-columns: (320: 2fr 27fr 10fr 5fr, 576: 1fr 38fr 4fr 5fr, 960: 1fr 41fr 4fr 2fr)))
    cursor: pointer
    +media((width: (320: 92%, 960: 100%)))
    margin: 0 auto rem(16)
    &.active
      margin-bottom: rem(8)
      .junctions-nav__toggler-chevron
        img
          transform: rotate(180deg)
    &-text
      grid-column-start: 2
      font-style: normal
      font-weight: bold
      +media((padding: (320: rem(15) rem(20) rem(15) 0, 960: rem(15) 0)))
      @extend .fs-12
      color: $light-black
    &-count
      display: flex
      align-items: center
      +media((justify-content: (320: center, 960: flex-start)))
      +media((padding-left: (320: 0, 960: rem(20))))
      flex-direction: row
      .count
        padding-left: rem(9)
        @extend .fs-12
        color: $black
        font-weight: bold
    &-chevron
      flex-direction: row
      justify-content: center
      align-items: center
      display: flex
      img
        transition: .5s
</style>
